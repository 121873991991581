/**
 * This file is used to define the feature toggles for the application
 * mapping with the environment variables.
 *
 * Obs: update the file `src/utils/env.ts` when adding/reveing feature toggles
 */

export const FeatureConfig = {
  apiUrl: 'VITE_API_URL',
  apiEventsUrl: 'VITE_API_EVENTS_URL',
  apiAdminUrl: 'VITE_API_ADMIN_URL',
  apiAdminUrlEndpointSuffix: 'VITE_API_ADMIN_URL_ENDPOINT_SUFFIX',
  countries: 'VITE_COUNTRIES',
}

export const FeatureToggle = {
  isOktaGlobal: 'VITE_OKTA_GLOBAL_ENABLED',
  customSettings: 'VITE_FEATURE_CUSTOM_SETTINGS_ENABLED',
  dataReports: 'VITE_FEATURE_DATA_REPORTS_ENABLED',
  journeyType: 'VITE_FEATURE_JOURNEY_TYPE_ENABLED',
  recurrenceDaySelector: 'VITE_FEATURE_RECURRENCE_DAY_SELECTOR_ENABLED',
  recurrence: 'VITE_FEATURE_RECURRENCE_ENABLED',
  sendPushCustomImage: 'VITE_FEATURE_SEND_PUSH_CUSTOM_IMAGE_ENABLED',
  sendPushCustomLandingPage: 'VITE_FEATURE_SEND_PUSH_LANDING_PAGE_ENABLED',
  scheduleDateRange: 'VITE_FEATURE_SCHEDULE_DATE_RANGE_ENABLED',
  timeExecutionWindow: 'VITE_FEATURE_TIME_EXECUTION_WINDOW_ENABLED',
  userRole: 'VITE_FEATURE_USER_ROLE_ENABLED',
  paramsDeliveryPoints: 'VITE_FEATURE_PARAMS_DELIVERY_POINTS_ENABLED',
  traitsOrders: 'VITE_FEATURE_TRAITS_ORDERS_ENABLED',
  traitsPoints: 'VITE_FEATURE_TRAITS_POINTS_ENABLED',
  traitsPromotions: 'VITE_FEATURE_TRAITS_PROMOTIONS_ENABLED',
  realTimeNotificationPage: 'VITE_FEATURE_REAL_TIME_NOTIFICATION_PAGE_ENABLED',
  realTimeNotificationDuration: 'VITE_FEATURE_REAL_TIME_NOTIFICATION_DURATION_ENABLED',
  realTimeNotificationTimeExecutionWindow: 'VITE_FEATURE_REAL_TIME_NOTIFICATION_TIME_EXECUTION_WINDOW_ENABLED',
  realTimeNotificationEditDetails: 'VITE_FEATURE_REAL_TIME_NOTIFICATION_EDIT_DETAILS_ENABLED',

  // Steps
  stepRequiresIntervention: 'VITE_FEATURE_STEP_REQUIRES_INTERVENTION_ENABLED',
  stepSendPush: 'VITE_FEATURE_STEP_SEND_PUSH_ENABLED',
  stepSendSms: 'VITE_FEATURE_STEP_SEND_SMS_ENABLED',
  stepSendWhatsApp: 'VITE_FEATURE_STEP_SEND_WHATSAPP_ENABLED',

  // WIP
  editPublished: 'VITE_FEATURE_EDIT_PUBLISHED_ENABLED',
  sendPushLanguageButtons: 'VITE_FEATURE_SEND_PUSH_LANGUAGE_BUTTONS_ENABLED',
} as const
