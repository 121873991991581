import {CSVReader, CSVReaderProps} from '@/components/Drawer/ContentParams/CSVReader'
import {cn} from '@/helpers/shadcn/utils'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {InfoOrError} from '@/shared-components/InfoOrError.tsx'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {InputText} from '@/shared-components/InputText'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {DatePicker} from '@/shared-components/shadcn/datepicker'
import {format} from 'date-fns'
import {DateTime} from 'luxon'
import {Dropdown} from 'pepsico-ds'
import {DropdownItem} from 'pepsico-ds/dist/Molecules/Dropdown/DropdownChild'
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {FEEDBACK_DURATION} from '../../../config/constants'
import {OperatorTypeSchema, Operators, OperatorsType, ParamsSelector} from '../../../types/paramFilters/paramFilters'
import {
  MAX_INPUT_VALUE_LENGTH,
  isOperatorBoolean,
  isOperatorDate,
  isOperatorEnum,
  isOperatorInteger,
  isOperatorList,
  isOperatorString,
  operators,
  traitEnumOptions,
} from '../../../utils/consts'

export type ChangeFilterValueProps =
  | {
      key: 'operator'
      newValue: Operators
    }
  | {
      key: 'value'
      newValue: string
    }

export type FilterOptionsByParamProps = {
  currentParamSelector: ParamsSelector
  disabled: boolean
  onChangeFilterValue: (values: ChangeFilterValueProps) => void
}

type ParamInputType = 'number' | 'textarea' | 'text' | 'date' | 'enum'

const payloadDateFormatPattern = 'yyyy-MM-dd'

export const FilterOptionsByParam = ({
  currentParamSelector,
  disabled,
  onChangeFilterValue,
}: FilterOptionsByParamProps) => {
  const {selectedParam, selectedParamData, selectedParamType} = currentParamSelector
  const {formatMessage} = useIntl()
  const [inputValueType, setInputValueType] = useState<ParamInputType>('number')
  const [shouldHideValueInput, setShouldHideValueInput] = useState(false)
  const [textContentPage, setTextContentPage] = useState(1)
  const [isInvalidAmountValue, setIsInvalidAmountValue] = useState(false)
  const {isPublishedOrCompleted} = useJourneyMetaConfig()
  const [fileState, setFileState] = useState<Pick<CSVReaderProps, 'state' | 'message'> | null>()

  const msgTimeoutDelay = useRef<NodeJS.Timeout | null>(null)

  const {shouldBlockInput, paginatedInputValue, fillInputWithLargeValue} = useHandleParamInputValue(
    selectedParamData.value,
    textContentPage,
    setTextContentPage
  )

  useEffect(() => {
    const intervalId = fillInputWithLargeValue()

    return () => {
      clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const {operator, value} = selectedParamData

    initInputType(selectedParamType, operator, setInputValueType)

    setShouldHideValueInput(shouldHideOperatorInput(operator))
    setIsInvalidAmountValue(value === null)
  }, [selectedParamData, selectedParamType])

  const onOperatorValueDidChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = evt.target.value as Operators
    setShouldHideValueInput(shouldHideOperatorInput(newValue))
    onChangeFilterValue({key: 'operator', newValue})
  }

  const onValueDidChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = evt.target.value
    onChangeFilterValue({key: 'value', newValue})
  }

  const onDateDidChange = (date?: Date) => {
    const newValue = date ? format(date.toString(), payloadDateFormatPattern) : ''
    onChangeFilterValue({key: 'value', newValue})
  }

  const onEnumDidChange = (values?: DropdownItem[]) => {
    if (!values) {
      onChangeFilterValue({key: 'value', newValue: ''})
      return
    }
    const newValue = values.map(value => value.id).join(',')
    onChangeFilterValue({key: 'value', newValue})
  }

  const onFileDidSelect = (values: ChangeFilterValueProps) => {
    if (msgTimeoutDelay.current) clearInterval(msgTimeoutDelay.current)
    msgTimeoutDelay.current = setTimeout(() => setFileState(null), FEEDBACK_DURATION)

    if (!values.newValue) {
      setFileState({
        state: 'ERROR',
        message: formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_FILE_ERROR_VALIDATION'}),
      })
      return
    }
    setFileState({
      state: 'SUCCESS',
      message: formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_FILE_SUCCESS_VALIDATION'}),
    })
    onChangeFilterValue(values)
  }

  if (!selectedParam) {
    return null
  }

  const isTextArea = inputValueType === 'textarea'
  const isNumberOrText = ['text', 'number'].includes(inputValueType)
  const isDate = inputValueType === 'date'
  const isEnum = inputValueType === 'enum'

  const errorMessage = isInvalidAmountValue
    ? formatMessage({id: 'ENTRY_CONDITION_CONTENT.OPERATOR_VALUE_VALIDATION'})
    : undefined

  const selectedDateInJsDate = paginatedInputValue
    ? DateTime.fromFormat(paginatedInputValue, payloadDateFormatPattern).toJSDate()
    : undefined

  const traitOptionsList = traitEnumOptions[selectedParam] ?? []
  const hasOptionsList = traitOptionsList.length > 1

  const enumOptions = hasOptionsList
    ? traitOptionsList.map(id => {
        return {
          id,
          displayText: id,
          isBadge: false,
        }
      })
    : []

  const selectedEnumValues = paginatedInputValue
    ? paginatedInputValue
        .split(',')
        .filter(id => !!id)
        .map(id => {
          return {
            id,
            displayText: id,
            isBadge: false,
          }
        })
    : undefined

  const enumPlaceholderWorkaround = selectedEnumValues?.length
    ? selectedEnumValues.reduce((acc, value) => acc + value.displayText + ', ', '').slice(0, -2)
    : formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_VALUE'})

  return (
    <>
      <OperatorSelect
        className="flex w-full max-w-[301px] flex-col gap-2"
        onOperatorValueDidChange={onOperatorValueDidChange}
        value={selectedParamData.operator}
        selectedParamType={selectedParamType}
        disabled={disabled}
      />

      {!shouldHideValueInput && (
        <div className={cn('flex w-full flex-col gap-2', isTextArea ? '' : 'max-w-[301px]')}>
          {isTextArea && (
            <>
              <InputTextArea
                id="param-selector-operator-value"
                label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_VALUE'})}
                placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.OPERATOR_PLACEHOLDER_TEXTAREA'})}
                value={paginatedInputValue}
                onChange={onValueDidChange}
                error={errorMessage}
                disabled={disabled || shouldBlockInput}
              />

              {!isPublishedOrCompleted && <CSVReader onChange={onFileDidSelect} {...fileState} />}
            </>
          )}

          {isNumberOrText && (
            <InputText
              id="param-selector-operator-value"
              type={(inputValueType ?? 'number') as 'number' | 'text'}
              label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_VALUE'})}
              placeholder={formatMessage({id: 'GENERAL.DEFINE_VALUE_PLACEHOLDER'})}
              value={paginatedInputValue}
              onChange={onValueDidChange}
              error={errorMessage}
              disabled={disabled || shouldBlockInput}
              required
            />
          )}

          {isDate && (
            <DatePicker
              id="param-selector-operator-value"
              className="max-w-xs"
              title={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_VALUE'})}
              placeholder={formatMessage({id: 'ENTRY_CONDITION_CONTENT.SELECT_DATE'})}
              error={errorMessage}
              date={selectedDateInJsDate}
              setDate={onDateDidChange}
              disabled={disabled || shouldBlockInput}
            />
          )}

          {isEnum && (
            <>
              <Dropdown
                data-testid="param-selector-operator-value-dropdown"
                data-cy="param-selector-operator-value-dropdown"
                label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_VALUE'})}
                selection={isOperatorList(selectedParamData.operator) ? 'multiple' : 'single'}
                childList={enumOptions}
                placeholder={enumPlaceholderWorkaround}
                selectedValue={selectedEnumValues}
                setSelectedValue={onEnumDidChange}
                aria-disabled={disabled || shouldBlockInput}
                disabled={disabled || shouldBlockInput}
                error={!!errorMessage}
                isRequired
              />
              <InfoOrError error={errorMessage} />
            </>
          )}
        </div>
      )}
    </>
  )
}

const shouldHideOperatorInput = (operator: Operators) => {
  return isOperatorBoolean(operator)
}

const initInputType = (
  traitType: OperatorsType | '',
  operator: Operators,
  setInputValueType: (type: ParamInputType) => void
) => {
  switch (traitType) {
    case OperatorTypeSchema.Values.boolean:
    case OperatorTypeSchema.Values.integer:
      setInputValueType('number')
      break
    case '':
    case OperatorTypeSchema.Values.string:
      setInputValueType(isOperatorList(operator) ? 'textarea' : 'text')
      break
    case OperatorTypeSchema.Values.date:
      setInputValueType('date')
      break
    case OperatorTypeSchema.Values.enum:
      setInputValueType('enum')
      break
    default:
      throw new Error(`Invalid traitType [${traitType}]`)
  }
}

const useHandleParamInputValue = (
  selectedParamDataValue: string | null,
  textContentPage: number,
  setTextContentPage: (newPage: number) => void
) => {
  const inputValue = selectedParamDataValue ?? ''
  const shouldShowText = inputValue.length <= MAX_INPUT_VALUE_LENGTH

  const [shouldBlockInput, setShouldBlockInput] = useState(!shouldShowText)

  const paginatedInputValue = handleLargeValue(inputValue, textContentPage, shouldShowText)

  const fillInputWithLargeValue = () => {
    let index = textContentPage
    const intervalId = setInterval(() => {
      if (shouldShowText) return
      index += 1
      setTextContentPage(index)

      if (MAX_INPUT_VALUE_LENGTH * index > inputValue.length) {
        clearInterval(intervalId)
        setShouldBlockInput(false)
      }
    }, 200)

    return intervalId
  }

  return {
    shouldBlockInput,
    paginatedInputValue,
    fillInputWithLargeValue,
  }
}

const handleLargeValue = (value: string, page: number, showAllContent: boolean) => {
  if (showAllContent) return value
  return value.slice(0, MAX_INPUT_VALUE_LENGTH * page)
}

type OperatorSelectProps = {
  onOperatorValueDidChange: (evt: React.ChangeEvent<HTMLSelectElement>) => void
  value: Operators
  selectedParamType: OperatorsType
  disabled: boolean
  className?: string
}

const OperatorSelect = ({
  onOperatorValueDidChange,
  selectedParamType,
  value,
  disabled,
  className,
}: OperatorSelectProps) => {
  const {formatMessage} = useIntl()

  const operatorsBasedOnType = () => {
    let operatorsFiltered: ReturnType<typeof operators> = []
    switch (selectedParamType) {
      case OperatorTypeSchema.Values.boolean: {
        operatorsFiltered = [...operators().filter(operator => isOperatorBoolean(operator.value))]
        break
      }
      case OperatorTypeSchema.Values.integer: {
        operatorsFiltered = [...operators().filter(operator => isOperatorInteger(operator.value))]
        break
      }
      case OperatorTypeSchema.Values.string: {
        operatorsFiltered = [...operators().filter(operator => isOperatorString(operator.value))]
        break
      }
      case OperatorTypeSchema.Values.date: {
        operatorsFiltered = [...operators().filter(operator => isOperatorDate(operator.value))]
        break
      }
      case OperatorTypeSchema.Values.enum: {
        operatorsFiltered = [...operators().filter(operator => isOperatorEnum(operator.value))]
        break
      }
      default:
        throw new Error(`Invalid selectedParamType [${selectedParamType}]`)
    }
    return operatorsFiltered
  }

  return (
    <div className={className}>
      <InputSelector
        id="operator"
        label={formatMessage({id: 'ENTRY_CONDITION_CONTENT.PARAMETER_CONDITION_OPERATOR'})}
        value={value}
        disabled={disabled}
        onChange={onOperatorValueDidChange}
        required
      >
        {operatorsBasedOnType().map(operator => (
          <option value={operator.value} key={operator.value}>
            {formatMessage({id: operator.displayValue})}
          </option>
        ))}
      </InputSelector>
    </div>
  )
}
