import {Link} from 'pepsico-ds'

interface NotificationNameCellProps {
  name: string
  onClick: () => void
}

export const NotificationNameCell = ({name, onClick}: NotificationNameCellProps) => {
  return (
    <Link data-testid="notification-name-cell" onClick={onClick}>
      {name}
    </Link>
  )
}
