import {ENDPOINTS} from '@/config/constants'
import {pathBuilder} from '@/helpers/pathBuilder'
import api, {HTTPMethod} from '../helpers/api'
import {
  CreateNotificationRequest,
  CreateNotificationRequestSchema,
  CreateNotificationResponse,
  CreateNotificationResponseSchema,
  GetNotificationByIdRequest,
  GetNotificationByIdRequestSchema,
  GetNotificationByIdResponse,
  GetNotificationByIdResponseSchema,
  GetNotificationsRequest,
  GetNotificationsRequestSchema,
  GetNotificationsResponse,
  GetNotificationsResponseSchema,
} from '../types/Notifications'
import {
  GetAllTraitsRequest,
  GetAllTraitsRequestSchema,
  GetAllTraitsResponse,
  GetAllTraitsResponseSchema,
} from '../types/Traits'

export const getNotificationsService = (params: GetNotificationsRequest, signal?: AbortSignal) => {
  return api<GetNotificationsRequest, GetNotificationsResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_EVENTS,
    requestSchema: GetNotificationsRequestSchema,
    responseSchema: GetNotificationsResponseSchema,
    signal,
  })(params)
}

export const createNotificationService = api<CreateNotificationRequest, CreateNotificationResponse>({
  method: HTTPMethod.POST,
  path: ENDPOINTS.CREATE_EVENT,
  requestSchema: CreateNotificationRequestSchema,
  responseSchema: CreateNotificationResponseSchema,
})

export const updateNotificationService = (id: string, payload: CreateNotificationRequest) =>
  api<CreateNotificationRequest, CreateNotificationResponse>({
    method: HTTPMethod.PUT,
    path: pathBuilder(ENDPOINTS.UPDATE_EVENT).withId(id).build(),
    requestSchema: CreateNotificationRequestSchema,
    responseSchema: CreateNotificationResponseSchema,
  })(payload)

export const getNotificationByIdService = (id: string, signal?: AbortSignal) => {
  return api<GetNotificationByIdRequest, GetNotificationByIdResponse>({
    method: HTTPMethod.GET,
    path: pathBuilder(ENDPOINTS.LOAD_EVENT).withId(id).build(),
    requestSchema: GetNotificationByIdRequestSchema,
    responseSchema: GetNotificationByIdResponseSchema,
    signal,
  })()
}

export const getAllTraitsService = (params: GetAllTraitsRequest, signal?: AbortSignal) => {
  return api<GetAllTraitsRequest, GetAllTraitsResponse>({
    method: HTTPMethod.GET,
    path: ENDPOINTS.LOAD_TRAITS,
    requestSchema: GetAllTraitsRequestSchema,
    responseSchema: GetAllTraitsResponseSchema,
    signal,
  })(params)
}
