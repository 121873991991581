import {StepConfigLoadingOrError} from '@/components/Drawer/ContentParams/StepConfigLoader/StepConfigLoadingOrError'
import {STEP_CONFIG} from '@/config/constants'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {GetSendPushStepConfigResponse, GetSendWhatsAppStepConfigResponse} from '@/types/StepConfigParams'
import {getStepConfigUseCase} from '@/useCases/getStepConfigUseCase'
import {isSUT} from '@/utils/env'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {PropsWithChildren} from 'react'

type StepConfigLoaderProps = PropsWithChildren & {
  type: NodeTypeEnum
  fetchFromRemote?: boolean
}

export const StepConfigLoader = ({type, fetchFromRemote = true, children}: StepConfigLoaderProps) => {
  const queryClient = useQueryClient()
  const {isFetching, isError, isSuccess} = useQuery({
    queryKey: [STEP_CONFIG.DATA_QUERY_KEY, type],
    queryFn: async ({signal}: {signal: AbortSignal}) => {
      const result = await getStepConfigUseCase(type, signal)
      switch (type) {
        case NodeTypeEnum.sendPush:
          return (result as GetSendPushStepConfigResponse) ?? {}
        case NodeTypeEnum.sendWhatsApp:
          return (result as GetSendWhatsAppStepConfigResponse) ?? {}
        default:
          throw new Error(`Unsupported step type: ${type}`)
      }
    },
    retry: isSUT() ? false : 1,
    staleTime: 3 * 60 * 1000, // 3 minutes cache
    enabled: fetchFromRemote,
  })

  if (!fetchFromRemote) {
    return <>{children}</>
  }

  const onDidClickTryAgain = () => {
    queryClient.invalidateQueries({queryKey: [STEP_CONFIG.DATA_QUERY_KEY, type]})
  }

  if (isFetching || isError) {
    return (
      <StepConfigLoadingOrError isFetching={isFetching} isError={isError} onDidClickTryAgain={onDidClickTryAgain} />
    )
  }

  if (!isSuccess) {
    return null
  }

  return <>{children}</>
}
