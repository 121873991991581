import {TEXT_TAG_ACTIVATION_CHAR} from '@/utils/consts'

export const enUS = {
  GENERAL: {
    APP_NAME: 'Journey Optimizer',
    LOADING: 'Loading...',
    ERROR: 'Something went wrong!',
    SUCCESS: 'Success!',
    SAVE_BUTTON: 'Save',
    SAVING_BUTTON: 'Saving...',
    TRY_AGAIN_BUTTON: 'Try again',
    CANCEL_BUTTON: 'Cancel',
    CLOSE_BUTTON: 'Close',
    ADD_NEW_PARAMETER_BUTTON: 'Add New Parameter',
    DEFINE_VALUE_PLACEHOLDER: 'Define value',
    REQUIRED_FIELD: '*Required',
    AND_FOR_ITEMS: ' and ',
    AND_FOR_MULTIPLE_ITEMS: ', and ',
    SELECT_MARKET: 'Select Market:',
  },
  STEP_NAME: {
    ENTRY_CONDITION: 'Entry Conditions',
    WAIT: 'Wait',
    SEND_SMS: 'Send SMS',
    SEND_PUSH: 'Send Push',
    SEND_WHATSAPP: 'Send WhatsApp',
    REQUIRES_INTERVENTION: 'Requires Intervention',
  },
  STATUS: {
    NEW: 'Draft',
    DRAFT: 'Draft',
    SAVED: 'Draft',
    PUBLISHED: 'Published',
    COMPLETED: 'Completed',
    DELETED: 'Deleted',
  },
  JOURNEY_LIST: {
    CREATE_JOURNEY_BUTTON: 'Create Journey',
    WELCOME_MESSAGE: 'Welcome, {userName}!',
    LOADING_TABLE_LABEL: 'Loading...',
    ERROR_TABLE_LABEL: 'Something went wrong!',
    TABLE_HEADER: 'Communication Journeys',
    TABLE_HEADER_SUBTITLE:
      'Review the journeys in the list below to ensure that users are receiving the right communications.',
    TABLE_NAME_COLUMN: 'Journey Name',
    TABLE_TYPE_COLUMN: 'Type',
    TABLE_DESCR_COLUMN: 'Description',
    TABLE_STATUS_COLUMN: 'Status',
    TABLE_COUNTRY_COLUMN: 'Country',
    TABLE_CREATED_AT_COLUMN: 'Created on',
    TABLE_MODIFIED_AT_COLUMN: 'Modified on',
    TABLE_PUBLISHED_AT_COLUMN: 'Published on',
  },
  NOTIFICATION_LIST: {
    WELCOME_MESSAGE: 'Welcome, {userName}!',
    TABLE_HEADER: 'Real-Time Notification',
    TABLE_HEADER_SUBTITLE:
      'Review the notification in the list below to ensure that users are receiving the right communications.',
    CREATE_NOTIFICATION_BUTTON: 'Create Notification',
    TABLE_ID_COLUMN: 'NOTIFICATION NAME',
    TABLE_TYPE_COLUMN: 'TYPE',
    TABLE_EVENT_COLUMN: 'EVENT',
    TABLE_STATUS_COLUMN: 'STATUS',
    TABLE_PUBLISHED_COLUMN: 'PUBLISHED ON',
    NO_NOTIFICATIONS_FOUND: 'No notifications found!',
    LOADING_TABLE_LABEL: 'Loading...',
    ERROR_TABLE_LABEL: 'Something went wrong!',
  },
  HEADER: {
    USER_GUIDE_LINK: 'User guide',
  },
  USER_GUIDE: {
    CONTEXT_JOURNEY_EDITOR_TITLE: 'Communication Journey Editor',
    CONTEXT_SETTINGS_TITLE: 'Settings',
    SECTION_CREATE_TITLE: 'Creating a new Communication Journey',
    SECTION_AUDIENCE_CRITERIA_TITLE: 'Defining the Audience criteria',
    SECTION_STEPS_TITLE: 'Adding Steps to the Communication Journey',
    SECTION_SAVE_TITLE: 'Saving the Communication Journey',
    SECTION_PUBLISH_TITLE: 'Publishing the Communication Journey',
    SECTION_DELETE_TITLE: 'Deleting a Communication Journey',
    SECTION_EXECUTION_STATUS_TITLE: 'Execution status',
    SECTION_MESSAGES_THRESHOLD_TITLE: 'Configuring message threshold limits',
  },
  JOURNEY_BUILDER: {
    TITLE: 'Communication Journey Editor',
    DRAWER_TITLE: 'Journey Editor',
    CONTENT_HEADER_NEW: 'Create a new communication Journey',
    CONTENT_HEADER_SAVED: 'Communication Journey',
    CONTENT_SUBHEADER:
      'Fill in the information related to the Journey, then create the steps and provide their configuration',
    EDITOR_HEADER: 'Journey Details',
    EDITOR_SUBHEADER: 'Please provide the required information to start creating a new journey',
    NAME_LABEL: 'Name of the Journey',
    COPY_NAME_LABEL: 'Name of the new Journey',
    REQUIRED_LABEL: '*Required',
    REQUIRED_LABEL_ERROR: 'Required field must be filled',
    CREATE_JOURNEY_HEADER: 'Create a new journey',
    DESCRIPTION_LABEL: "Journey's Description",
    NAME_INPUT_PLACEHOLDER: 'Type name of the Journey',
    DESCRIPTION_INPUT_PLACEHOLDER: 'Type here Journey Description',
    DESCRIPTION_INPUT_LABEL: 'Journey Description',
    JOURNEY_TYPE_LABEL: 'Type of Journey',
    JOURNEY_TYPE_PLACEHOLDER: 'Select type of Journey',
    JOURNEY_TYPE_HINT:
      'Once you proceed to the next page, you will not be able to change the journey type. Please make sure your selection is final.',
    JOURNEY_TYPE_HINT2: 'Recurrent journeys cannot be changed to One-time journeys.',
    JOURNEY_TYPE_ONE_TIME: 'One-time',
    JOURNEY_TYPE_RECURRENT: 'Recurrent',
    COUNTRY: 'Country',
    DURATION: 'Duration',
    DURATION_START_DATE_INFO: 'If no start date is selected, it will start on the day the journey is published.',
    DURATION_VALUE_START_ONLY_LABEL: 'From {start}',
    DURATION_VALUE_LABEL: 'From {start} till {end}',
    PUBLISH_BUTTON: 'Publish',
    DATE_RANGE_LABEL: 'Schedule date range for execution:',
    DATE_COUNTRY_LABEL: "Journey's country local time: {date} ({timezone})",
    CREATE_COPY_BUTTON: 'Make a copy',
    CONTINUE_BUTTON: 'Continue',
    DELETE_BUTTON: 'Delete',
    SAVING_BUTTON: 'Saving...',
    SAVE_DRAFT_BUTTON: 'Save as Draft',
    BACK_BUTTON: 'Back',
    CANCEL_BUTTON: 'Cancel',
    ADD_STEP_BUTTON: 'Add Step',
    EXPORT_RECORDS_BUTTON: 'Export records',
    ENTRY_CONDITION_WIDGET_LABEL: 'Entry Conditions',
    WAIT_WIDGET_LABEL: 'Wait',
    SEND_PUSH_WIDGET_LABEL: 'Send Push',
    SEND_SMS_WIDGET_LABEL: 'Send SMS',
    SEND_WHATSAPP_WIDGET_LABEL: 'Send WhatsApp',
    REQUIRES_INTERVENTION_WIDGET_LABEL: 'Requires Intervention',
    REQUIRES_INTERVENTION_MESSAGE:
      'Audience records that did not engage in any other steps requires an intervention action. The journey will be finished and no other step can be added after it.',
    REQUIRED_CONFIG_MESSAGE: 'Missing required configuration',
    SHORT_REQUIRED_CONFIG_MESSAGE: 'Missing configuration',
    STEP_SELECTOR_HEADER: 'Select the Step to add',
    ADD_NEW_STEP_HEADER: 'Add a New Step',
    ADD_NEW_STEP_SUBHEADER: 'Please provide the required information to start creating a new journey',
    ADD_NEW_STEP_TYPE_SELECT: 'Select the type of step that you want to add',
    ADD_NEW_SELECT_STEP_EMPTY_OPTION: 'Select step',
    STEP_CONFIG_REQUIRED_FIELDS: 'Fill in the required information',
    STEP_EXECUTION_STATUS_COMPLETED: 'Completed',
    STEP_EXECUTION_STATUS_EXECUTED: 'Executed',
    STEP_EXECUTION_STATUS_CREATED: 'Created',
    STEP_EXECUTION_STATUS_RUNNING: 'Running',
    STEP_EXECUTION_STATUS_ON_HOLD: 'On Hold',
    STEP_EXECUTION_STATUS_SKIPPED: 'Skipped',
    STEP_EXECUTION_STATUS_CANCELED: 'Canceled',
    STEP_EXECUTION_STATUS_FAILED: 'Failed',
    STEP_EXECUTION_STATUS_INTERRUPTED: 'Interrupted',
    PUBLISH_CONFIRM_MODAL_MESSAGE: 'Once you publish, this action cannot be reversed. Would you like to continue?',
    PUBLISH_CONFIRM_MODAL_TITLE: 'Warning',
    PUBLISH_CONFIRM_MODAL_CONFIRM: 'Proceed',
    PUBLISH_CONFIRM_MODAL_START_DATE_WARNING:
      "Please note that the set starting date is in the past, and it will be automatically adjusted to today's date upon publication.",
    PUBLISH_WARNING_MODAL_MESSAGE:
      'There are no specific criteria set for this journey. This means that the entire database will be the target of this journey. Do you want to proceed?',
    PUBLISH_WARNING_MODAL_TITLE: 'Warning',
    PUBLISH_WARNING_MODAL_CONFIRM: 'Publish anyway',
    DELETE_WARNING_MODAL_MESSAGE: 'Once you delete, this action cannot be reversed. Would you like to continue?',
    DELETE_WARNING_MODAL_TITLE: 'Warning',
    DELETE_WARNING_MODAL_CONFIRM: 'Proceed',
    EXPORT_JOURNEY: 'Execution report',
    COPY_JOURNEY_MODAL_TITLE: 'Duplicate Journey',
    COPY_JOURNEY_MODAL_INFO: 'You will create a copy of the current Journey and duplicate its settings.',
    COPY_JOURNEY_MODAL_INFO_PART2: 'Please assign a name to the new journey:',
    COPY_JOURNEY_MODAL_CONFIRM: 'Confirm',
    DELETE_STEP_WARNING_MODAL_TITLE: 'Delete Step',
    DELETE_STEP_WARNING_MODAL_CONTENT: 'This action cannot be reversed. Are you sure you want to delete this Step?',
    DELETE_STEP_WARNING_MODAL_CONFIRM: 'Delete',
    STEP_NODE_INFOS_MORE: '{quantity} more',
    RECURRENCE_TITLE: 'Recurrence',
    RECURRENCE_LABEL: 'Repeat every',
    RECURRENCE_INFO: 'This journey will be repeated X times.',
    RECURRENCE_INFO_MONTHLY: 'It will repeat on the same day it starts or on its publication date.',
    RECURRENCE_SELECT: 'Select Recurrence',
    RECURRENCE_OPTION_DAILY: 'Daily',
    RECURRENCE_OPTION_WEEKLY: 'Weekly',
    RECURRENCE_OPTION_MONTHLY: 'Monthly',
    RECURRENCE_SUMMARY_FREQUENCY_DAILY: 'Repeats every day',
    RECURRENCE_SUMMARY_FREQUENCY_WEEKLY: 'Repeats every week',
    RECURRENCE_SUMMARY_FREQUENCY_WEEKLY_DAYS: 'Repeats every week on {days}',
    RECURRENCE_SUMMARY_FREQUENCY_MONTHLY: 'Repeats on the same day every month as the publication date',
    RECURRENCE_SUMMARY_FREQUENCY_MONTHLY_PARTIAL:
      'Repeats every day {start_day} of every month, starting on {start_date}',
    RECURRENCE_SUMMARY_FREQUENCY_MONTHLY_COMPLETE:
      'Repeats every day {start_day} of every month, starting on {start_date} till {end_date}',
    TIME_EXECUTION_WINDOW_TITLE: 'Time Execution Window',
    TIME_EXECUTION_WINDOW_START: 'Start Time',
    TIME_EXECUTION_WINDOW_END: 'End Time',
    TIME_EXECUTION_WINDOW_SELECT_START: 'Select Start Time',
    TIME_EXECUTION_WINDOW_SELECT_END: 'Select End Time',
    TIME_EXECUTION_WINDOW_ADD_NEW_BUTTON: 'Add New Time Window',
    TIME_EXECUTION_VALUE_START_ONLY_LABEL: 'From {start}',
    TIME_EXECUTION_VALUE_LABEL: 'From {start} till {end}',
    PUBLISHED_AT_LABEL: 'on {date}',
  },
  NOTIFICATION_BUILDER: {
    CREATE_NOTIFICATION_HEADER: 'New Real-Time Notification',
    EDITOR_SUBHEADER: 'Please provide the required information to create a New Trigger Based Notification',
    NOTIFICATION_NAME_LABEL: 'Notification Name',
    NOTIFICATION_DESCRIPTION_LABEL: 'Notfication Description',
    NOTIFICATION_NAME_PLACEHOLDER: 'Type here',
    COUNTRY_LABEL: 'Country',
    STATUS_LABEL: 'Status',
    EVENT_LABEL: 'Event',
    SELECT_EVENT: 'Select event',
    SELECT_EVENT_OPERATOR: 'Select operator',
    SELECT_EVENT_VALUE: 'Select value',
    DETAILS_SUBHEADER: 'Fill in the notification details',
    IF_FALLS_ON_BANK_HOLIDAY_LABEL: 'If it falls on a bank holiday, send it on',
    BANK_HOLIDAY_DEFAULT_OPTION: 'Select preferred date',
    BANK_HOLIDAY_SAME_DAY_OPTION: 'On the same day',
    BANK_HOLIDAY_NEXT_DAY_OPTION: 'On the next business day',
    REDIRECT_USER_TO_THIS_PAGE_LABEL: 'Redirect User to this page',
    REDIRECT_USER_TO_THIS_PAGE_DEFAULT_OPTION: 'Select page',
    NOTIFICATION_TYPE_LABEL: 'Notification Type',
    SUBJECT_LABEL: 'Subject',
    SUBJECT_PLACEHOLDER: 'Type Subject Message',
    MESSAGE_LABEL: 'Message',
    MESSAGE_PLACEHOLDER: 'Type message',
    SAVE_BUTTON: 'Save',
    PREVIEW_BUTTON: 'Preview',
    SAVE_AS_DRAFT_BUTTON: 'Save as Draft',
    PUBLISH_BUTTON: 'Publish',
    CANCEL_BUTTON: 'Cancel',
    NOTIFICATION_DEFAULT_OPTION: 'Select notification type',
    SELECT_PAGE: 'Select page',
    ERROR_DETAILS_LABEL: 'Something went wrong!',
    DEFINE_VALUE_PLACEHOLDER: 'Define value',
    PARAMETER_CONDITION_VALUE: 'Value',
    SELECT_VALUE: 'Select value(s)',
    OPERATOR_VALUE_VALIDATION: 'Value must be defined',
  },
  NOTIFICATION_DETAILS: {
    NOTIFICATION_PREVIEW_HEADER: 'Please review the preview and then click on publish.',
    NOTIFICATION_HEADER: 'Real-Time Notification Details',
    NOTIFICATION_NAME: 'Notification Name',
    NOTIFICATION_DESCRIPTION: 'Notification description',
    EDIT_BUTTON: 'Edit',
    PAUSE_BUTTON: 'Pause',
    COUNTRY: 'Country',
    EVENT: 'Event',
    EVENT_OPERATOR: 'Event Operator',
    EVENT_VALUE: 'Event Value',
    PARAMETER_CONDITION_TRAIT: 'Trait',
    PARAMETER_CONDITION_OPERATOR: 'Operator',
    PARAMETER_CONDITION_VALUE: 'Value',
    DURATION: 'Duration',
    DURATION_VALUE: '{start} - {end}',
    TIME_EXECUTION_WINDOW_TITLE: 'Time Execution Window',
    TIME_EXECUTION_WINDOW_VALUE: '{start} - {end}',
    RECURRENCE_TITLE: 'Recurrence',
    SEND_IT_ON_TITLE: 'If it falls on a bank holiday, send it on',
    NOTIFICATION_DETAILS_SECTION_TITLE: 'Notification details',
    NOTIFICATION_DETAILS_SECTION_SUBTITLE: 'Notification Subject',
    NOTIFICATION_DETAILS_SECTION_DESCRIPTION: 'Message text goes here, max 140 characters',
    GO_TO_PAGE_BUTTON: 'Go to page',
    LOADING_DETAILS_LABEL: 'Loading Details...',
    ERROR_DETAILS_LABEL: 'Something went wrong!',
  },
  TRAIT_PARAM: {
    activeCurrentMonthYesNo: 'Active in the current month',
    activeL3M: 'Active in the last 3 months',
    assignedSegments: 'Assigned Segments',
    city: 'City',
    daysRemainingUntilPointExpiryDate: 'Days Remaining Until Point Expiry Date',
    daysSinceLastDeliveryDate: 'Days Since Last Delivery',
    daysSinceLastLogin: 'Days Since Last Login',
    daysSinceLastOrderDate: 'Days Since Last Order',
    daysSincePromotionStart: 'Days Since Promotion Start',
    daysSinceRegistration: 'Days Since Registration',
    daysUntilNextDeliveryDate: 'Days Until Next Delivery',
    daysUntilNextOrderDate: 'Days Until Next Order',
    district: 'District',
    divisionCode: 'Division Code',
    nextOrderDate: 'Next Order Date',
    portalTypeValue: 'Store Type',
    promotionId: 'Promotion ID',
    promotionStartDate: 'Promotion Start Date',
    promotionEndDate: 'Promotion End Date',
    registeredDate: 'Registration Date',
    registeredStoreYesNo: 'Registered',
    salesChannelCode: 'Sales Channel Code',
    salesOfficeCode: 'Sales Office Code',
    state: 'State Name',
    storeId: 'Store ID',
    storeStatusName: 'Registration Status',
    territory: 'Territory',
  },
  TRAIT_OPERATOR: {
    lessThan: 'Less than',
    lessOrEqual: 'At most',
    equalTo: 'Is equal to',
    notEqualTo: 'Is not equal to',
    greaterOrEqual: 'At least',
    greaterThan: 'Greater than',
    in: 'Only included in',
    notIn: 'Excluding any of',
    isYes: 'Yes',
    isNo: 'No',
  },
  ENTRY_CONDITION_CONTENT: {
    REENTRY_DAYS: 'Re-entry days',
    REENTRY_DAYS_HELP: 'Leave it empty if the audience should not be re-evaluated.',
    REENTRY_DAYS_ERROR: 'Value must be defined as greater than 0',
    SELECT_DATE: 'Select date(s)',
    SELECT_OPERATOR: 'Select operator',
    SELECT_VALUE: 'Select value(s)',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    SCHEDULE_DATE_RANGE_HINT: '{icon} Select start date. End date is optional',
    LOAD_FILE_CSV_LABEL: 'Load values list from a CSV file',
    CONFIG_SUBTITLE: 'Select the parameters for the entry conditions',
    CONFIGURE_YOUR_AUDIENCE: 'Configure your audience',
    SELECT_A_PARAMETER: 'Select a parameter',
    PARAMETER: 'Parameter {position}',
    PARAMETER_CONDITION_TRAIT: 'Trait',
    PARAMETER_CONDITION_OPERATOR: 'Operator',
    PARAMETER_CONDITION_VALUE: 'Value',
    OPERATOR_VALUE_VALIDATION: 'Value must be defined',
    OPERATOR_PLACEHOLDER_TEXTAREA: 'List of comma separated values, i.e.: 001,002,003...',
    OPERATOR_PLACEHOLDER_DATE: 'mm-dd-yyyy',
    SELECT_FILE_ERROR_VALIDATION: 'Selected file is invalid or empty!',
    SELECT_FILE_SUCCESS_VALIDATION: 'Values successfully added to the field!',
    LOAD_MODE_TEXT_CONTENT: 'Show more data',
    LOAD_ALL_TEXT_CONTENT: 'Show all data',
    RESET_ALL_PARAMS: 'Reset All Filters',
  },
  SEND_NOTIFICATION_CONTENT: {
    RECIPIENT_LABEL: 'Recipient',
    MESSAGE_PLACEHOLDER: 'Type message',
    SUBJECT_PLACEHOLDER: 'Type subject message',
    INVALID_LENGTH_ERROR: "Value length can't be empty",
    INVALID_LENGTH_ERROR_ABOVE_LIMIT: "Value length can't reach its limit",
    LANGUAGE_LABEL: 'Language',
    SUBJECT_LABEL: 'Subject',
    MESSAGE_LABEL: 'Message',
    AUTO_COMPLETE_HINT: `You can type '${TEXT_TAG_ACTIVATION_CHAR}' to use tags`,
    STORE_OWNER_RECIPIENT: 'Store owner',
    NO_TEMPLATE_SELECTED: 'No template selected',
    CHOOSE_TEMPLATE: 'Choose message template:',
    SELECT_TEMPLATE: 'Select message template to send',
    SELECT_PUSH_IMAGE: 'Select image to send',
    SELECT_LANDING_PAGE: 'Redirect user to this page',
    ERROR_LOADING_STEP: 'Error loading step, please try again later!',
    ERROR_LOADING_TEMPLATES: 'Error loading message templates, please try again later!',
  },
  WAIT_CONTENT: {
    CONFIGURE_LABEL: 'Waiting time',
    AMOUNT_DAYS_PLACEHOLDER: 'Define value',
    AMOUNT_DAYS_VALIDATION: 'Value must be defined as greater than 0',
  },
  SETTINGS: {
    TITLE: 'Settings',
    LOAD_ERROR: 'Settings could not be loaded.',
    SAVE_ERROR: 'Settings could not be saved.',
    SAVE_SUCCESS: 'Settings have been saved!',
    THRESHOLD_HEADING: 'Configure message threshold limits',
    THRESHOLD_SUBHEADING:
      'Define how many messages (maximum) a target can be notified on a daily/weekly/monthly basis. {br}Leave the fields empty to remove the limit.',
    THRESHOLD_DAILY_LABEL: 'Max per day',
    THRESHOLD_WEEKLY_LABEL: 'Max per week',
    THRESHOLD_MONTHLY_LABEL: 'Max per month',
    NO_THRESHOLD_LIMIT: 'Unrestricted',
    CUSTOM_THRESHOLD_HEADING: 'Country specific settings',
    CUSTOM_THRESHOLD_SUBHEADING: 'Values defined in this section will have precedence over the global configuration.',
    CUSTOM_THRESHOLD_ADD_BUTTON: 'Add a new country configuration',
    CUSTOM_THRESHOLD_REMOVE_BUTTON: 'Remove',
  },
  LOGOUT: {
    HEADER: 'Journey Optimizer',
    LOGOUT_BUTTON: 'Logout',
  },
  PAGE_ERROR: {
    FORBIDDEN_HEADER: 'Oops, you do not have access!',
    FORBIDDEN_SUBHEADER: 'Please send a request accesss through portal.',
    FORBIDDEN_RETURN_BUTTON: 'Return to home page',
    NOT_FOUND_HEADER: 'Oops, page not found!',
    NOT_FOUND_SUBHEADER: 'The page you are looking for could not be found.',
    NOT_FOUND_RETURN_BUTTON: 'Go to the home page',
    SERVER_ERROR_HEADER: 'Oops, something went wrong!',
    SERVER_ERROR_SUBHEADER: 'The server encountered an error and could not complete your request.',
    SERVER_ERROR_RETURN_BUTTON: 'Go to the home page',
  },
  UNSAVED_CHANGES: {
    MODAL_TITLE: 'You have unsaved changes.',
    MODAL_CONTENT: 'If you leave this page without saving, your changes will be lost.',
    MODAL_PRIMARY_BUTTON: 'Leave without saving',
    MODAL_SECONDARY_BUTTON: 'Stay and save',
    WARNING_LABEL: 'There are unsaved changes. {icon}',
  },
  DAYS: {
    MON: 'Mon',
    TUE: 'Tue',
    WED: 'Wed',
    THU: 'Thu',
    FRI: 'Fri',
    SAT: 'Sat',
    SUN: 'Sun',
    MON_PLURAL_LABEL: 'Mondays',
    TUE_PLURAL_LABEL: 'Tuesdays',
    WED_PLURAL_LABEL: 'Wednesdays',
    THU_PLURAL_LABEL: 'Thursdays',
    FRI_PLURAL_LABEL: 'Fridays',
    SAT_PLURAL_LABEL: 'Saturdays',
    SUN_PLURAL_LABEL: 'Sundays',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BR: 'Brazil',
    CL: 'Chile',
    MX: 'Mexico',
    PL: 'Poland',
    TR: 'Turkey',
    US: 'United States',
    XX: 'SUT Country',
    SK: 'Slovakia',
  },
}

export default enUS
