import {CreateNotificationRequest, CreateNotificationRequestSchema} from '@/types/Notifications'
import {ParameterItem, Parameters} from '@/types/Traits'

export const createNotificationRequestBody = (
  countryId: number,
  eventStatus: number,
  notificationName: string,
  notificationDescription: string,
  eventSelectors: ParameterItem,
  paramSelectors: Parameters,
  subject: string,
  message: string
): CreateNotificationRequest => {
  const event = {
    id_trait: eventSelectors.trait.id,
    id_operator: eventSelectors.operator.id,
    id_traitvalue: eventSelectors.predefinedValue?.id ?? -1,
    value: eventSelectors.predefinedValue?.name ?? null,
  }

  const parameters = paramSelectors.map(parameterItem => {
    const updatedItem = {
      id_traitparameter: parameterItem.trait?.id,
      id_operator: parameterItem.operator?.id,
      id_traitparametervalue: parameterItem?.predefinedValue?.id ?? null,
      id_operatorvalue: parameterItem?.predefinedValue?.id ?? null, // [Task 13374760] should be id_traitparametervalue
      value: parameterItem.value ?? null,
    }
    return updatedItem
  })

  const notificationData = {
    id_country: countryId,
    id_eventstatus: eventStatus,
    name: notificationName,
    description: notificationDescription,
    active: true,
    entry_conditions: [
      {
        ...event,
        traitparameters: parameters,
      },
    ],
    steps: [
      {
        id_step: 1,
        id_steptype: 1,
        id_stepstatus: 1,
        message_configs: [
          {
            id_language: 1,
            name_language: 'English',
            subject,
            message,
          },
        ],
      },
    ],
  }

  const validatedData = CreateNotificationRequestSchema.parse(notificationData)

  return validatedData
}
