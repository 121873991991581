import {ParamSelector} from '@/components/ParamsFilterCard/ParamSelector/ParamSelector'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {AddNewParameterButton} from '@/shared-components/AddNewParameterButton'
import {initialParamsFiltersConfig} from '@/store/data/initialParamsFiltersConfig'
import {useRef} from 'react'
import {OperatorsSchema, ParamsSelector} from '../../types/paramFilters/paramFilters'

interface Props {
  paramsSelectors: Array<ParamsSelector>
  setParamsSelectors: (newParams: Array<ParamsSelector>) => void
}

export const ParamsFilterCard: React.FC<Props> = ({paramsSelectors, setParamsSelectors}) => {
  const listRef = useRef<HTMLDivElement>(null)

  const {isPublishedOrCompleted} = useJourneyMetaConfig()

  const updateSelectedParam = (id: number, updatedParamSelector: ParamsSelector | null) => {
    if (isPublishedOrCompleted) return

    const updatedParamsSelectors = paramsSelectors
      .map(item => (item.id === id ? updatedParamSelector : item))
      .filter((item): item is ParamsSelector => !!item)

    setParamsSelectors(
      updatedParamsSelectors.length ? [...updatedParamsSelectors] : initialParamsFiltersConfig.paramsSelectors
    )
  }

  const addParamSelector = () => {
    if (isPublishedOrCompleted) return
    const lastFilterSelector = paramsSelectors[paramsSelectors.length - 1]

    setParamsSelectors([
      ...paramsSelectors,
      {
        id: lastFilterSelector.id + 1,
        selectedParam: '',
        selectedParamType: '',
        conditional: 'AND',
        selectedParamData: {
          operator: OperatorsSchema.Values.lessThan,
          value: null,
        },
      },
    ])

    setTimeout(() => {
      if (!listRef.current) return
      listRef.current.scrollTop = Number.MAX_SAFE_INTEGER
    }, 10)
  }

  const hasMultipleParamsSelectors = paramsSelectors.length > 1

  return (
    <div ref={listRef} data-testid="params-filter-card" data-cy="params-filter-card">
      {paramsSelectors.map((paramsSelector, idx) => {
        return (
          <div
            className="mb-4 w-full max-w-[678px] rounded-lg border-2 border-dashed border-blue bg-layer-02 p-4 transition-all duration-300 hover:border-layer-03"
            key={paramsSelector.id}
          >
            <ParamSelector
              position={idx}
              isPublished={isPublishedOrCompleted}
              currentParamSelector={paramsSelector}
              hasMultipleParamsSelectors={hasMultipleParamsSelectors}
              key={paramsSelector.id}
              updateSelectedParam={updateSelectedParam}
            />
          </div>
        )
      })}

      {!isPublishedOrCompleted && (
        <AddNewParameterButton
          data-testid="add-filter-button"
          data-cy="add-filter-button"
          className="max-w-xs"
          onClick={addParamSelector}
        />
      )}
    </div>
  )
}
