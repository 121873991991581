import {CustomImageSelector} from '@/components/SendNotification/CustomImageSelector'
import {LandingPageSelector} from '@/components/SendNotification/LandingPageSelector'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {GetSendPushStepConfigResponse} from '@/types/StepConfigParams'
import {useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  getData: () => GetSendPushStepConfigResponse
  setSubject: (value: string) => void
  setMessage: (value: string) => void
  subject: string
  message: string
}

export default function SendPushParams({getData, setMessage, setSubject, subject, message}: Props) {
  const {formatMessage} = useIntl()
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null)
  const [selectedPage, setSelectedPage] = useState<string | null>(null)
  const landingPageOptions = getData()?.landingPages ?? []
  const imageOptions = getData()?.images ?? []

  const handleImageChange = (imageId: string) => {
    setSelectedImageId(imageId)
  }

  const handlePageChange = (pageId: string) => {
    setSelectedPage(pageId)
  }

  return (
    <div className="h-42 flex w-full max-w-xs flex-col gap-3" data-testid="send-push-params">
      <InputTextArea
        label={formatMessage({id: 'NOTIFICATION_BUILDER.SUBJECT_LABEL'})}
        placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.SUBJECT_PLACEHOLDER'})}
        onChange={e => {
          setSubject(e.target.value)
        }}
        value={subject}
        maxLength={100}
        id="notification-subject"
        required
      />
      <InputTextArea
        label={formatMessage({id: 'NOTIFICATION_BUILDER.MESSAGE_LABEL'})}
        placeholder={formatMessage({id: 'NOTIFICATION_BUILDER.MESSAGE_PLACEHOLDER'})}
        onChange={e => {
          setMessage(e.target.value)
        }}
        value={message}
        maxLength={100}
        id="notification-message"
        required
      />
      <LandingPageSelector
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SELECT_LANDING_PAGE'})}
        options={landingPageOptions}
        value={selectedPage || landingPageOptions[0]?.id}
        onChange={handlePageChange}
        disabled={false}
      />
      <CustomImageSelector
        label={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.SELECT_PUSH_IMAGE'})}
        options={imageOptions}
        value={selectedImageId || imageOptions[0]?.id}
        onChange={handleImageChange}
        disabled={false}
      />
    </div>
  )
}
