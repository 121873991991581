import {FeatureToggle} from '@/config/featureToggles'
import {isFeatureEnabled} from '@/helpers/featureToggle'
import {getInputErrorMessage} from '@/helpers/getErrorMessage'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {TEXT_TAG_ACTIVATION_CHAR, textTagSuggestions} from '@/utils/consts'
import {FilterDropdownMenuList} from 'pepsico-ds'
import React, {ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

interface AutoCompleteProps {
  inputValue?: string
  onChangeInput: (newValue: string) => void
  label?: string
  max_message_length: number
  inputId?: string
  required?: boolean
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  inputValue = '',
  label,
  onChangeInput,
  max_message_length,
  inputId,
  required,
}) => {
  const {isPublishedOrCompleted} = useJourneyMetaConfig()
  const [presentSuggestions, setPresentSuggestions] = useState(false)
  const [lastTagStartingPoint, setLastTagStartingPoint] = useState(0)
  const inputFieldRef = useRef<HTMLTextAreaElement>(null)
  const {formatMessage} = useIntl()

  const onChangeMessage = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isPublishedOrCompleted) return

    const message = e.target.value
    const cursorPosition = Number(e.target.selectionStart)

    updateCursor(message, cursorPosition)
    onChangeInput(message)
  }

  const updateCursor = (newMessage: string, position?: number) => {
    if (!position) {
      setPresentSuggestions(false)
      return
    }

    const shouldPresentSuggestions = newMessage[position - 1] === TEXT_TAG_ACTIVATION_CHAR
    if (shouldPresentSuggestions) {
      setLastTagStartingPoint(position)
    }
    setPresentSuggestions(shouldPresentSuggestions)
  }

  const onClickSuggestion = (suggestion: string) => {
    const firstPartMessage = inputValue.slice(0, lastTagStartingPoint)
    const secondPartMessage = inputValue.slice(lastTagStartingPoint, inputValue.length)
    const newMessage = `${firstPartMessage}{${suggestion}} ${secondPartMessage}`

    setPresentSuggestions(false)
    onChangeInput(newMessage)
    inputFieldRef.current?.focus()
  }

  const inputActionProps = {
    onChange: onChangeMessage,
    onBlur: () => setPresentSuggestions(false),
    onKeyDown: (evt: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (evt.key !== 'Escape') {
        return
      }
      setPresentSuggestions(false)
    },
  }

  const suggestions = useMemo(() => {
    return textTagSuggestions.filter(tag => {
      if (['AmountPointsToExpire', 'CutOffTime', 'Today'].includes(tag)) {
        return isFeatureEnabled(FeatureToggle.paramsDeliveryPoints)
      }
      return true
    })
  }, [])

  const errorMessage = getInputErrorMessage(inputValue, max_message_length, formatMessage)

  return (
    <div>
      <InputTextArea
        label={label}
        placeholder={formatMessage({id: 'SEND_NOTIFICATION_CONTENT.MESSAGE_PLACEHOLDER'})}
        innerRef={inputFieldRef}
        value={inputValue}
        className="h-40 text-sm"
        error={errorMessage}
        disabled={isPublishedOrCompleted}
        name="text"
        id={inputId}
        maxLength={max_message_length}
        maxWordsCounter={isPublishedOrCompleted ? undefined : max_message_length}
        info={isPublishedOrCompleted ? undefined : formatMessage({id: 'SEND_NOTIFICATION_CONTENT.AUTO_COMPLETE_HINT'})}
        required={required}
        showEmojiPicker
        {...inputActionProps}
      />

      <SuggestionsPopOver
        suggestions={suggestions}
        inputFieldRef={inputFieldRef}
        lastTagStartingPoint={lastTagStartingPoint}
        visible={presentSuggestions}
        onSelect={onClickSuggestion}
        inputValue={inputValue}
      />
    </div>
  )
}

type SuggestionsPopOverProps = {
  suggestions: string[]
  inputFieldRef: React.RefObject<HTMLTextAreaElement>
  lastTagStartingPoint?: number
  visible?: boolean
  onSelect?: (suggestion: string) => void
  inputValue: string
}

const SuggestionsPopOver = ({
  suggestions,
  inputFieldRef,
  lastTagStartingPoint = 0,
  visible = false,
  onSelect,
  inputValue,
}: SuggestionsPopOverProps) => {
  const [textWidth, setTextWidth] = useState(0)
  const [textHeight, setTextHeight] = useState(0)
  const inputLeftPos = inputFieldRef.current?.getBoundingClientRect().left ?? window.innerWidth / 2
  const inputTopPos = inputFieldRef.current?.getBoundingClientRect().top ?? window.innerHeight / 2

  useEffect(() => {
    if (!inputFieldRef.current) return

    const inputElementAttr = window.getComputedStyle(inputFieldRef.current)
    const inputElementAttrFontSize = inputElementAttr.getPropertyValue('font-size')
    const inputElementAttrPadding = inputElementAttr.getPropertyValue('padding')
    const inputElementAttrWidth = inputElementAttr.getPropertyValue('width')

    const InputWidthWithoutPadding =
      Number(inputElementAttrWidth.slice(0, -2)) - 2 * Number(inputElementAttrPadding.slice(0, -2))

    const textValue = inputValue.slice(0, lastTagStartingPoint)

    const tempElement = document.createElement('span')
    tempElement.textContent = textValue
    tempElement.style.visibility = 'hidden'
    tempElement.style.whiteSpace = 'nowrap'
    tempElement.style.position = 'absolute'
    tempElement.style.fontSize = inputElementAttrFontSize

    document.body.appendChild(tempElement)
    setTextWidth(tempElement.offsetWidth % InputWidthWithoutPadding)
    document.body.removeChild(tempElement)

    const tempForHeightElement = document.createElement('span')
    tempForHeightElement.textContent = textValue || '0'
    tempForHeightElement.style.visibility = 'hidden'
    tempForHeightElement.style.position = 'absolute'
    tempForHeightElement.style.fontSize = inputElementAttrFontSize
    tempForHeightElement.style.width = inputElementAttrWidth
    tempForHeightElement.style.whiteSpace = 'break-spaces'
    tempForHeightElement.style.padding = inputElementAttrPadding

    document.body.appendChild(tempForHeightElement)
    setTextHeight(tempForHeightElement.offsetHeight)
    document.body.removeChild(tempForHeightElement)
  }, [inputValue, inputFieldRef, lastTagStartingPoint])

  const topOffset = -200

  return (
    <div
      style={{
        left: `${inputLeftPos + 10 + textWidth}px`,
        top: `${inputTopPos + topOffset + textHeight}px`,
        opacity: visible ? 1 : 0,
        visibility: visible ? 'visible' : 'hidden',
        transition: '0.2s all',
      }}
      data-testid="tags-suggestions-wrapper"
      className="fixed"
    >
      <FilterDropdownMenuList
        handleSelectId={item => onSelect?.(item)}
        options={suggestions.map(suggestion => ({
          id: suggestion,
          label: suggestion,
        }))}
        selection="single"
        className="w-full p-0"
      />
    </div>
  )
}
