import {CountrySelector} from '@/components/JourneyEditor/components/CountrySelector'

import {onSaveJourney} from '@/helpers/onSaveJourney'
import {useCountries} from '@/hooks/useCountries'
import {BorderlessButton} from '@/shared-components/Button'
import {InputText} from '@/shared-components/InputText'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {useAppDispatch, useAppSelector} from '@/store'
import {JourneyStatus} from '@/types/JourneyStatus'
import {setJourneyStatus} from '@ReduxActions'
import {useMutation} from '@tanstack/react-query'
import _ from 'lodash'
import {Icon} from 'pepsico-ds'
import React, {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import {ROUTES} from '../../../config/constants'
import Modal from '../../../shared-components/Modal/Modal'
import {saveJourneyUseCase} from '../../../useCases'

type NewJourneyConfig = {
  name: string
  description: string
  country: string
}
type ModalProps = {
  newJourneyCopyConfig: NewJourneyConfig
  setNewJourneyCopyConfig: (newConfig: NewJourneyConfig) => void
}

type Props = {
  setIsFloatingMenuOpen?: (isOpen: boolean) => void
}

export const CreateJourneyCopyButton: React.FC<Props> = ({setIsFloatingMenuOpen}) => {
  const {formatMessage} = useIntl()
  const [isCopying, setIsCopying] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentJourneyConfig = useAppSelector(state => state.journeyConfig)

  const prevPath = location.state ? location.state.prevPath : ROUTES.LIST_JOURNEYS

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newJourneyCopyConfig, setNewJourneyCopyConfig] = useState({
    name: '',
    description: currentJourneyConfig.description,
    country: currentJourneyConfig.country,
  })

  const {mutate, isPending: isLoading} = useMutation({
    mutationKey: ['saveJourney'],
    mutationFn: saveJourneyUseCase,
  })

  const isJourneySavedAtLeastOnce = window.location.pathname.includes('journey/')
  const isDisabled = !isJourneySavedAtLeastOnce || isLoading

  const copyJourney = useCallback(() => {
    if (isCopying || isDisabled) return

    setIsCopying(true)
    dispatch(setJourneyStatus(JourneyStatus.draft))
    mutate(
      {
        ..._.omit(currentJourneyConfig, ['id', 'status']),
        ...newJourneyCopyConfig,
        id: undefined,
        isCopy: true,
      },
      {
        onSuccess: result => {
          onSaveJourney.Success(isCopying, () => {
            navigate(ROUTES.EDIT_JOURNEY.replace(':id', result.id), {
              state: {
                prevPath,
              },
            })
          })
        },
        onError: err => {
          onSaveJourney.Error(err)
          dispatch(setJourneyStatus(JourneyStatus.saved))
          setIsCopying(false)
        },
      }
    )
    setIsModalOpen(false)
    setIsFloatingMenuOpen?.(false)
  }, [
    isCopying,
    isDisabled,
    newJourneyCopyConfig,
    currentJourneyConfig,
    dispatch,
    mutate,
    setIsFloatingMenuOpen,
    navigate,
    prevPath,
  ])

  const showModal = useCallback(() => {
    if (isDisabled) return
    setIsModalOpen(true)
  }, [setIsModalOpen, isDisabled])

  return (
    <>
      <CopyButton showModal={showModal} isDisabled={isDisabled} />

      <Modal
        onClose={() => {
          setIsFloatingMenuOpen?.(false)
          setIsModalOpen(false)
        }}
        onClickSecondaryButton={() => {
          setIsFloatingMenuOpen?.(false)
          setIsModalOpen(false)
        }}
        onClickPrimaryButton={() => copyJourney()}
        isOpen={isModalOpen}
        title={formatMessage({id: 'JOURNEY_BUILDER.COPY_JOURNEY_MODAL_TITLE'})}
        primaryButtonText={formatMessage({id: 'JOURNEY_BUILDER.COPY_JOURNEY_MODAL_CONFIRM'})}
        content={
          <CopyModalContent
            newJourneyCopyConfig={newJourneyCopyConfig}
            setNewJourneyCopyConfig={setNewJourneyCopyConfig}
          />
        }
        primaryButtonDisabled={!newJourneyCopyConfig.name}
      />
    </>
  )
}

type CopyButtonProps = {
  showModal: () => void
  isDisabled?: boolean
}

const CopyButton = ({showModal, isDisabled = false}: CopyButtonProps) => {
  const {formatMessage} = useIntl()

  return (
    <BorderlessButton
      data-testid="create-copy-button"
      data-cy="create-copy-button"
      onClick={showModal}
      size="small"
      disabled={isDisabled}
      className="hover:fill-button-primary-hover hover:text-button-primary-hover enabled:fill-primary enabled:text-primary disabled:fill-link-disabled disabled:text-link-disabled"
    >
      <div className="flex items-center gap-1">
        <Icon icon="file_copy" size="small" />
        <p className="text-xs font-bold">{formatMessage({id: 'JOURNEY_BUILDER.CREATE_COPY_BUTTON'})}</p>
      </div>
    </BorderlessButton>
  )
}

const CopyModalContent: React.FC<ModalProps> = ({newJourneyCopyConfig, setNewJourneyCopyConfig}) => {
  const {formatMessage} = useIntl()

  const onChangeInput = (param: keyof typeof newJourneyCopyConfig, newValue: string) => {
    setNewJourneyCopyConfig({...newJourneyCopyConfig, [param]: newValue})
  }

  const {countries} = useCountries()

  const error = !newJourneyCopyConfig.name.length
    ? formatMessage({id: 'JOURNEY_BUILDER.REQUIRED_LABEL_ERROR'})
    : undefined

  return (
    <div className="mt-2">
      <p className="text-sm leading-6 text-text-primary">
        {formatMessage({id: 'JOURNEY_BUILDER.COPY_JOURNEY_MODAL_INFO'})}
      </p>
      <p className="text-sm leading-6 text-text-primary">
        {formatMessage({id: 'JOURNEY_BUILDER.COPY_JOURNEY_MODAL_INFO_PART2'})}
      </p>
      <div className="mt-6 flex max-w-xs flex-col gap-6">
        <InputText
          required
          autoFocus
          id="new-journey-copy-name"
          label={formatMessage({id: 'JOURNEY_BUILDER.COPY_NAME_LABEL'})}
          error={error}
          value={newJourneyCopyConfig.name}
          placeholder={formatMessage({id: 'JOURNEY_BUILDER.NAME_INPUT_PLACEHOLDER'})}
          onChange={e => onChangeInput('name', e.target.value)}
        />

        <CountrySelector
          id="journey-country-copy"
          label={formatMessage({id: 'JOURNEY_BUILDER.COUNTRY'})}
          countries={countries}
          status={JourneyStatus.draft}
          value={newJourneyCopyConfig.country}
          onValueChanged={e => onChangeInput('country', e)}
          required
        />

        <InputTextArea
          label={formatMessage({id: 'JOURNEY_BUILDER.DESCRIPTION_INPUT_LABEL'})}
          id="new-journey-copy-description"
          placeholder={formatMessage({id: 'JOURNEY_BUILDER.DESCRIPTION_INPUT_PLACEHOLDER'})}
          value={newJourneyCopyConfig.description}
          onChange={e => onChangeInput('description', e.target.value)}
          className="h-32"
        />
      </div>
    </div>
  )
}
