import {StepConfigLoader} from '@/components/Drawer/ContentParams/StepConfigLoader/StepConfigLoader'
import {SendPushStepConfigParams} from '@/components/Drawer/ContentParams/StepConfigParams/SendPushStepConfigParams'
import {SendSmsStepConfigParams} from '@/components/Drawer/ContentParams/StepConfigParams/SendSmsStepConfigParams'
import {SendWhatsAppStepConfigParams} from '@/components/Drawer/ContentParams/StepConfigParams/SendWhatsAppStepConfigParams'
import {DrawerConfigTitle} from '@/components/Drawer/DrawerConfigTitle'
import {STEP_CONFIG} from '@/config/constants'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {useAppSelector} from '@/store'
import {NodeTypeForSendNotificationEnum} from '@/types/NodeDataForSendNotification'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {GetSendPushStepConfigResponse, GetSendWhatsAppStepConfigResponse} from '@/types/StepConfigParams'
import {StepSendPushNotification, StepSendSMS, StepSendWhatsApp} from '@/types/Steps'
import {useQueryClient} from '@tanstack/react-query'
import React from 'react'

interface Props {
  type: NodeTypeForSendNotificationEnum
}

export const SendNotificationParams: React.FC<Props> = ({type}) => {
  const {id: currentNodeId} = useAppSelector(state => state.currentNode)
  const config = useAppSelector(state => {
    const node = state.nodesData[currentNodeId] as StepSendPushNotification | StepSendSMS | StepSendWhatsApp
    return node?.config
  })

  const queryClient = useQueryClient()

  if (!config) {
    return null
  }

  const getStepConfigData = {
    sendPush: () =>
      queryClient.getQueryData([STEP_CONFIG.DATA_QUERY_KEY, NodeTypeEnum.sendPush]) as GetSendPushStepConfigResponse,
    sendWhatsApp: () =>
      queryClient.getQueryData([
        STEP_CONFIG.DATA_QUERY_KEY,
        NodeTypeEnum.sendWhatsApp,
      ]) as GetSendWhatsAppStepConfigResponse,
  }

  const shouldFetchFromRemote =
    isFeatureEnabled(FeatureToggle.sendPushCustomImage) || isFeatureEnabled(FeatureToggle.sendPushCustomLandingPage)

  return (
    <>
      <DrawerConfigTitle />

      <div className="flex flex-col gap-6" data-testid="send-notification-main-content">
        {type === NodeTypeEnum.sendSMS && <SendSmsStepConfigParams />}

        {type === NodeTypeEnum.sendPush && (
          <StepConfigLoader type={NodeTypeEnum.sendPush} fetchFromRemote={shouldFetchFromRemote}>
            <SendPushStepConfigParams getData={getStepConfigData.sendPush} />
          </StepConfigLoader>
        )}

        {type === NodeTypeEnum.sendWhatsApp && (
          <StepConfigLoader type={NodeTypeEnum.sendWhatsApp} fetchFromRemote={shouldFetchFromRemote}>
            <SendWhatsAppStepConfigParams getData={getStepConfigData.sendWhatsApp} />
          </StepConfigLoader>
        )}
      </div>
    </>
  )
}
