import {Drawer} from '@/components/Drawer/Drawer'
import EventNotificationDetails from '@/components/Drawer/EventNotificationDetails'
import {getNotificationTableColumns} from '@/components/EventListTable/components/NotificationTableColumn'
import {ROUTES, countryCodeToId} from '@/config/constants'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {getNotificationsUseCase} from '@/useCases/getNotificationsUseCase'
import {useQuery} from '@tanstack/react-query'
import {PaginationState, SortingState} from '@tanstack/react-table'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {DataTable} from '../../shared-components/DataTable'

type requestParams = {
  idCountry: number
  pageNumber: number
  page: number
  pageSize: number
  sortBy: string
}

export default function EventListTable() {
  const {country} = useJourneyMetaConfig()
  const initialRequestParams = useMemo(() => {
    return {
      idCountry: countryCodeToId[country],
      pageNumber: 0,
      page: 0,
      pageSize: 10,
      sortBy: 'name,asc',
    }
  }, [country])

  const {formatMessage} = useIntl()
  const [sorting, setSorting] = useState<SortingState>([])

  const [requestParams, setRequestParams] = useState<requestParams>(initialRequestParams)
  const navigate = useNavigate()

  const {id: notificationId} = useParams()
  const {pathname} = useLocation()
  const isEditing = pathname?.includes('/edit')

  const isEventDetailsOpen = !!notificationId && !isEditing

  useEffect(() => {
    const countryId = countryCodeToId[country]
    setRequestParams(() => ({
      ...initialRequestParams,
      idCountry: countryId,
    }))
  }, [country, initialRequestParams])

  const {isFetching, data, error} = useQuery({
    queryKey: ['notifications', requestParams],
    queryFn: ({signal}) => getNotificationsUseCase(requestParams, signal),
  })

  const onPaginationDidChange = useCallback(
    ({pageIndex, pageSize}: PaginationState) => {
      setRequestParams({...requestParams, pageNumber: pageIndex, pageSize})
    },
    [setRequestParams, requestParams]
  )

  const onSortingDidChange = useCallback(
    (sorting: SortingState) => {
      const column = sorting[0]
      const sortIndicator = column?.desc ? 'DESC' : 'ASC'
      const newSort = !column ? '' : `${column.id},${sortIndicator}`
      setRequestParams({...requestParams, sortBy: newSort})
      setSorting(sorting)
    },
    [setSorting, setRequestParams, requestParams]
  )

  const onEventNotificationDidClick = (notificationId: string) => {
    navigate(`${ROUTES.EVENT_NOTIFICATION.replace(':id', notificationId)}`)
  }

  const onCloseDetailsDidClick = () => {
    navigate(`${ROUTES.EVENT_NOTIFICATIONS}`)
  }

  return (
    <div data-testid="event-list-table-container">
      {isFetching && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-loading">
          {formatMessage({id: 'NOTIFICATION_LIST.LOADING_TABLE_LABEL'})}
        </p>
      )}
      {!isFetching && error && (
        <p className="text-md my-16 items-center text-center" data-testid="notification-error">
          {formatMessage({id: 'NOTIFICATION_LIST.ERROR_TABLE_LABEL'})}
        </p>
      )}
      {!isFetching && !error && (
        <DataTable
          columns={getNotificationTableColumns(formatMessage, onEventNotificationDidClick)}
          data={data?.content ?? []}
          pagination={{pageIndex: requestParams.pageNumber, pageSize: requestParams.pageSize}}
          pageCount={data?.pagination?.totalPages ?? 1}
          onPaginationDidChange={onPaginationDidChange}
          sortingColumn={sorting}
          onSortingDidChange={onSortingDidChange}
          emptyMessage={formatMessage({id: 'NOTIFICATION_LIST.NO_NOTIFICATIONS_FOUND'})}
          data-testid="notification-content"
        />
      )}
      <Drawer isOpen={isEventDetailsOpen} onClose={onCloseDetailsDidClick} direction="right">
        {!!notificationId && <EventNotificationDetails notificationId={notificationId} />}
      </Drawer>
    </div>
  )
}
