import {GetAllTraitsResponse} from '@/types/Traits'
import {getAllTraitsService} from '../services/notificationsService'

export type GetAllTraitsUseCaseParams = {
  traitType: string
}

export async function getAllTraitsUseCase(
  params: GetAllTraitsUseCaseParams,
  signal?: AbortSignal
): Promise<GetAllTraitsResponse> {
  return getAllTraitsService(params, signal)
}
